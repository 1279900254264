import React, { useEffect, useState } from 'react';
import { useLiff } from 'react-liff';

import './App.scss';

const App = () => {
  // const [displayName, setDisplayName] = useState('');
  const [userId, setUserId] = useState('')
  const { error, isLoggedIn, isReady, liff } = useLiff();

  useEffect(() => {
    if (!isLoggedIn) return;

    (async () => {
      const profile = await liff.getProfile();
      // setDisplayName(profile.displayName);
      setUserId(profile.userId)
    })();
  }, [liff, isLoggedIn]);

  const showDisplayName = () => {
    if (error) return <p className="flex items-center text-danger">Something is wrong. <span className="block text-sm">{ error }</span></p>;
    if (!isReady) return <p className="flex items-center text-white text-center m-auto">...Loading...</p>;

    if (!isLoggedIn) {
      return (
        <button className="App-button bg-gray-1 rounded-md py-2 px-4 w-3/4 m-auto text-white font-promptsemibold" onClick={liff.login}>
          ยอมรับว่าฉันเป็นผู้จำหน่ายยาสูบอายุมากกว่า 18ปี
        </button>
      );
    } else {
      
      (async()=>{
        let endpoint = ''
        // let apiLocation = '';
        // let apiXKey = ''

        switch(window.location.origin) {
          // Local
          case "https://localhost:3000": 
            endpoint = 'https://vipecoupon-sandbox.vivi-th.com'
            break;
            
          // Staging
          case "https://liffecoupon-sandbox.vivi-th.com":
            endpoint = 'https://vipecoupon-sandbox.vivi-th.com'
            break;
  
          // Production
          default:
            endpoint = 'https://vipecoupon.vivi-th.com'
            break;
        }


        const url = endpoint+'/?id='+userId
        // console.log(url)
        if(userId) {
          window.location.href = url
        }

      })()

    }
    return (
      <p className="flex items-center text-center m-auto"  onClick={liff.logout}>...Loading...</p>
    );
  };

  return (
    <div className="App max-w-2xl m-auto">
        <header className="App-header h-screen">
          <div className="flex justify-center h-screen bg-gradient-to-b from-bluetop to-bluebottom">
            <div className="flex flex-col self-center -translate-y-24">
              <h1 className="mb-1 text-3xl font-promptsemibold text-center text-white">ยินดีต้อนรับ!</h1>
              <h1 className="mb-3 text-3xl font-serif text-center text-white">VIP Coupon</h1>
              { showDisplayName() }

            </div>
          </div>
        </header>
    </div>
  );
};

export default App;